import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Main.css";

function Main() {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState(
    localStorage.getItem("selectedLanguage") || "Geo"
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = () => {
    const newLang = lang === "En" ? "Geo" : "En";
    setLang(newLang);
    localStorage.setItem("selectedLanguage", newLang);
  };

  const titleEn = [
    `Guidelines for Architects' Professional Activity`,
    `Recommendations for Calculating Prices for Architectural Services`,
  ];
  const descEn = `The document was consolidated based on the experience and Guidelines
        of the International Union of Architects (UIA),
        the American Institute of Architects (AIA),
        the Royal Institute of British Architects (RIBA),
        the Royal Institute of Canadian Architects (RAIC) and Practices of other countries.`;
  const introEn = [
    `Architecture is a rapidly changing, knowledge-based professional service industry. Fees for architectural services should include expenses, risks, investments and profits. Qualitative characteristics take into account the interests of the customer, profession and society. Architects should ascertain a pricing system based on experiences and practices found in other countries in order to avoid unhealthy competition at the expense of quality.`,
    `Architects are professionally responsible not only to the client or the user of the space, but also to society and the country. This responsibility is partially regulated by the Georgian law "On architectural activity" (Article 1. Purpose of the law: The purpose of this law is to establish a Faultless, ecologically friendly, visually pleasing environment for human life on the territory of Georgia and to develop Architecture as art). In the context of public health and safety, architects are obligated to respond to the public interest and needs of citizens. It should be noted that society's demands, health and safety standards are constantly improving and responding to global challenges. The challenges of sustainable development and climate change, accessibility, mobility, biodiversity, cultural heritage and energy efficiency, in the context of economic development and increasing global urbanization, are among the professional responsibilities of architects.`,
    `The Calculator for the costs of architectural services should be implemented in such a way that it includes both the architect's expenses, various risks and adequate income, which will in turn help architects to improve the quality of their professional activities. It is also meant to help experienced architects to devote appropriate time and resources to the education of new professionals. The expediency of compensation is measured not only by the architect's obligations outlined in his/her contract, but also by the architectural ethics and general obligations of the professionalism that any architect should have to the country and to the society.`,
    `As a worldwide Recognized fact, that the architect's work and the results of his services have major impact on the environment and the quality of life of society. In the Developed countries of the world, the decision-making process when dealing with architectural selection is not based on price of the services, but rather on high quality of the Architect. Competition among architects worldwide is based on overall quality of the project. `,
    `The system based on the advantage of the low cost of the project (also assimilated by state procurement agency) reduces the quality of architectural services, the quality of architectural projects and as a result damages the living environment in the long run, which clearly affects the architectural appearance of Georgian cities and the quality of life of citizens.`,
    `In response to the Global and domestic challenges existing in the country, it is important to bring the compensation of intellectual labor into a logical system, otherwise the fields that require creative work will be in great danger. In the context of architectural activity, this threat is directly reflected on the everyday life and safety of society.`,
    `The purpose of the guidelines and the fee Calculator of the architectural project is to protect the interests of both the representatives of the professional field and the clients, to determine the fair range of the cost of the architectural projects, taking into account the function of the project, the complexity of Design, the total area and other data, in order to promote the role of Architecture in the country and the increase quality of professional services. The mentioned platform for fee Calculator, which is of a recommendatory nature, helps not only the architect, but also the Client/Investor, to determine the cost of the service, and to whom this website provides detailed information on what factors are affecting the cost of architectural services. The transparency of the project fee calculator will contribute to the formation of a healthy, quality-oriented, competitive professional environment in the field and will protect the customers from unplanned costs.`,
    `It is important to understand that at the fundamental level, the architect receives compensation for the design services provided to the client, therefore the architect must provide the client with comprehensive information about the cost of architectural services; how the architect's ideas and experience can lead to significant increases in the real estate or commercial value of the building and also it could provide savings in building operating costs; The services of a professional architect is a better investment in the long-term sustainability of the investment than the minimal initial cost.`,
    `The world practice gives us the opportunity to develop a document as a recommendation and a platform based on mathematical Formula for Calculating the Value  Which will bring some clarity to the principles of the Architects work. It will show the public the importance of architectural activity and create a window of opportunity for quality growth; It will allow us and create the conditions to raise a new generation of professionals, taking into account the constant technological innovations and the growing demands of the field.`,
  ];
  const serviceEn = [
    `The architectural design process includes several stages, from concept to realization. The proposed platform represents four stages of project services, with recommended percentages corresponding to the share of the total cost of the project:`,
    ``,
    `The architect must provide the client with an accurate idea of what the architectural services will include at any stage of planning. The payment for additional services must be determined based on a mutual agreement between the parties after a detailed description of the work.`,
    `As a standard, it should be defined that any state tax, landscaping fee, etc., as well as construction supervision, travel costs are additional, not included in the price of the architect's services and added to the contract if requested by the client.`,
  ];
  const service_listEn = [
    "Concept - 15%",
    "Project Outline - 30%",
    "Documentation needed for Permit- 30%",
    "Documentation needed for Construction (Working Documentation) - 25%",
  ];
  const calc_platformEn = [
    `The mathematical formula for calculating the cost of architectural design was developed on the basis of world experience and Georgian practice.`,
    `The smaller the size of the project, the higher the project cost per square meter of its area, and accordingly, the cost per square meter decreases with the increase of the construction area.`,
    `The calculation formula takes into account the main factors that determine the complexity of the architectural project and thus affect the cost of the service.`,
    `The individual factor - be it the complexity of the building (according to categories), the type of service (new project, reconstruction, immovable monument of cultural heritage), the project area (regulation zones) - has been given the corresponding price-regulating coefficient:`,
    `In addition, there is a price determining factor, which depends on the individual architect and project. The mentioned factor is considered on the calculating platform as a standard coefficient, which the architect sets according to his experience and qualifications.`,
    `After entering the appropriate data on the Calculating platform, it is possible to automatically generate a commercial proposal (download in pdf format)`,
  ];
  const goalEn = [
    `The automatic calculator on the web-platform will help architects and potential clients to determine the cost of architectural services.`,
    `The Initiators for creating the calculator for Architectural services and costs are active Georgian architects.`,
    `The prices provided on the web-platform represent the recommended minimum of the design cost.`,
    `The society of professional architects of Georgia, share the necessity to regulate the field and increase the quality (თუ Value?) of professional activity.`,
  ];

  const title = [
    `არქიტექტორთა პროფესიული საქმიანობის სახელმძღვანელო დოკუმენტი`,
    `რეკომენდაციები არქიტექტურული პროექტის ღირებულების ანგარიშისთვის`,
  ];
  const desc = `დოკუმენტი შედგენილია არქიტექტორთა საერთაშორისო კავშირის (UIA)
        არქიტექტორთა ამერიკული ინსტიტუტის (AIA) ბრიტანეთის არქიტექტორთა
        სამეფო ინსტიტუტის (RIBA) კანადის არქიტექტორთა სამეფო ინსტიტუტის
        (RAIC) და სხვა ქვეყნების გამოცდილებისა და სახელმძღვანელო
        დოკუმენტების გათვალისწინებით.`;
  const intro = [
    `არქიტექტურა არის სწრაფად ცვალებადი, ცოდნაზე დაფუძნებული პროფესიული
        მომსახურების ინდუსტრია. არქიტექტურული მომსახურების ანაზღაურება
        უნდა მოიცავდეს ხარჯებს, რისკებს, ინვესტიციებსა და მოგებას.
        ხარისხობრივი მახასიათებლები ითვალისწინებს დამკვეთის, პროფესიის,
        საზოგადოების ინტერესებს. არქიტექტორებმა უნდა განსაზღვრონ ფასთა
        სისტემა, რომელიც დაეყრდნობა მსოფლიო გამოცდილებას და პრაქტიკას,
        რათა თავიდან აიცილონ არაჯანსაღი კონკურენცია ხარისხის კლების
        ხარჯზე.`,
    `არქიტექტორებს პროფესიული პასუხისმგებლობა აქვთ არა მხოლოდ
        დამკვეთის, ან სივრცის მომხმარებლის, არამედ საზოგადოებისა და
        ქვეყნის წინაშე. აღნიშნული პასუხისმგებლობა ნაწილობრივ რეგულირდება
        საქართველოში მოქმედი კანონით „არქიტექტურული საქმიანობის შესახებ“
        (მუხლი 1. კანონის მიზანი: ამ კანონის მიზანია საქართველოს
        ტერიტორიაზე ადამიანის ყოფაცხოვრებისთვის სრულყოფილი, ეკოლოგიურად
        უსაფრთხო, ესთეტიკური გარემოს ჩამოყალიბება, არქიტექტურული
        ხელოვნების განვითარება). საზოგადოებრივი ჯანმრთელობისა და
        უსაფრთხოების კონტექსტში არქიტექტორები ვალდებულნი არიან უპასუხონ
        საზოგადოებრივ ინტერესებსა და მოქალაქეების საჭიროებებს.
        აღსანიშნავია, რომ საზოგადოების მოთხოვნები, ჯანმრთელობისა და
        უსაფრთხოების სტანდარტები მუდმივად იზრდება, პასუხობს რა გლობალურ
        გამოწვევებს. მდგრადი განვითარებისა და კლიმატის ცვლილების
        გამოწვევები, მისაწვდომობა, მობილობა, ბიომრავალფეროვნება, კულტურული
        მემკვიდრეობა და ენერგოეფექტურობა, ეკონომიკური განვითარებისა და
        მზარდი გლობალური ურბანიზაციის ფონზე, არქიტექტორების პროფესიული
        პასუხისმგებლობის სფეროში შედის.`,
    `არქიტექტურული საქმიანობის ანაზღაურების ანგარიში უნდა განხორციელდეს
        ისე, რომ მოიცავდეს როგორც არქიტეტორის ხარჯებს, ისე სხვადასხვა
        რისკებსა და სათანადო შემოსავალს, რომელიც დაეხმარება არქიტექტორებს
        პროფესიული საქმიანობის სრულყოფასა და ხარისხის გაზრდაში, ასევე ხელს
        შეუწყობს გამოცდილ არქიტექტორებს, სათანადო დრო და რესურსი დაუთმონ
        ახალი პროფესიონალების აღზრდას. კომპენსაციის მიზანშეწონილობა
        იზომება არა მხოლოდ არქიტექტორის ვალდებულებებით, რომელიც
        ხელშეკრულებიდან გამომდინარეობს, არამედ არქიტექტურული ეთიკით და
        პროფესიონალის ზოგადი ვალდებულებებით, რომელიც მას გააჩნია ქვეყნისა
        და საზოგადოების წინაშე.`,
    `მსოფლიოში აღიარებულია არქიტექტორის საქმიანობისა და მისი
        მომსახურების შედეგების უდიდესი გავლენა გარემოსა და საზოგადოების
        ცხოვრების ხარისზე. განვითარებული სამყაროს მიდგომები არქიტექტურის
        მიმართ არჩევანს აკეთებს არა ფასის, არამედ ხარისხის მიხედვით.
        მსოფლიოს მასშტაბით არქიტექტორებს შორის კონკურენციაც სწორედ ხარისხს
        ეფუძნება.`,
    `პროექტის დაბალი ღირებულების უპირატესობაზე დაფუძნებული (მათ შორის,
        სახელმწიფო შესყიდვების) სისტემა, ამცირებს არქიტექტურული
        მომსახურებისა და არქიტექტურის ხარისხს და გრძელვადიანად აზიანებს
        საცხოვრებელ გარემოს, რაც ნათლად აისახება საქართველოს ქალაქების
        არქიტექტურულ იერსახეზე და მოქალაქეების ცხოვრების ხარისხზე.`,
    `ქვეყანაში არსებული, მათ შორის გლობალური, გამოწვევების საპასუხოდ
        მნიშვნელოვანია გონებრივი შრომის ანაზღეურების ლოგიკურ სისტემაში
        მოქცევა, სხვა შემთხვევაში დიდი საფრთხის ქვეშ აღმოჩნდება დარგები,
        რომელიც სრულად გონებრივ და შემოქმედებით რესურსს მოითხოვს.
        არქიტექტურული საქმიანობის კონტექსტში კი ეს საფრთხე პირდაპირ
        აისახება საზოგადოების ცხოვრებასა და უსაფრთხოებაზე.`,
    `სახელმძღვანელო დოკუმენტისა და არქიტექტურული პროექტის ღირებულების
        მოცემული საანგარიშოს მიზანია, დაიცვას როგორც პროფესიული
        საზოგადოების წარმომადგენლების, ისე დამკვეთების ინტერესები,
        განსაზღვროს არქიტექტურული პროექტის ღირებულების სამართლიანი
        დიაპაზონი, ობიექტის ფუნქციის, პროექტის სირთულის, ფართობისა და სხვა
        მონაცემების გათვალისწინებით, რათა ხელი შეუწყოს ქვეყანაში
        არქიტექტურული საქმიანობის განვითარებას და პროფესიული მომსახურების
        ხარისხის ზრდას. აღნიშნული საანგარიშო, რომელიც სარეკომენდაციო
        ხასიათისაა, მომსახურების ღირებულების დადგენაში ეხმარება არა მხოლოდ
        არქიტექტორს, , არამედ დამკვეთსაც, რომელსაც აწვდის დაზუსტებულ
        ინფორმაციას, თუ რა ფაქტორები ახდენს გავლენას არქიტექტურული
        მომსახურების ღირებულებაზე. პროექტის ღირებულების ანგარიშის
        გამჭვირვალობა ხელს შეუწყობს სფეროში ჯანსაღი, ხარისხზე
        ორიენტირებული კონკურენტული პროფესიული გარემოს ჩამოყალიბებას და
        დაიცავს დამკვეთებს დაუგეგმავი ხარჯებისგან.`,
    `მნიშვნელოვანია გავითვალისწინოთ, რომ ფუნდამენტურ დონეზე,
        არქიტექტორი ანაზღაურებას იღებს დამკვეთისთვის გაწეულ საპროექტო
        მომსახურებაში, შესაბამისად არქიტექტორმა უნდა მიაწოდოს დამკვეთს
        ამომწურავი ინფორმაცია არქიტექტურული მომსახურების ღირებულების
        შესახებ; თუ როგორ შეიძლება არქიტექტორის იდეებმა და გამოცდილებამ
        გამოიწვიოს შენობის უძრავი ქონების ან კომერციული ღირებულების
        მნიშვნელოვანი ზრდა და შენობის საექსპლუატაციო ხარჯების დაზოგვა; რომ
        პროფესიონალი არქიტექტორის მომსახურება კაპიტალის გრძელვადიან
        მდგრადობაში უკეთესი ინვესტიციაა, ვიდრე მინიმუმამდე დაყვანილი
        საწყისი ხარჯი.`,
    `მსოფლიო პრაქტიკის გათვალისწინება გვაძლევს შესაძლებლობას შევიმუშაოთ
        სარეკომენდაციო შინაარსის დოკუმენტი და საანგარიშო პლატფორმა,
        რომელიც გარკვეულ სიცხადეს შეიტანს დარგის მუშაობის პრინციპებში,
        საზოგადოებას დაანახებს არქიტექტურული საქმიანობის მნიშვნელობას და
        ხარისხის ზრდის შესაძლებლობის ფანჯარას შექმნის; საშუალებას მოგვცემს
        და პირობებს შექმნის, რომ მუდმივი ტექნოლოგიური სიახლეებისა და
        დარგის მიმართ მზარდი მოთხოვნების გათვალისწინებით, აღიზარდოს
        პროფესიონალთა ახალი თაობა.`,
  ];
  const service = [
    `არქიტექტურული პროექტირების პროცესი მოიცავს რამდენიმე ეტაპს,
        კონცეფციიდან რეალიზაციამდე. შემოთავაზებულ სანგარიშო პლატფორმაზე
        წარმოდგენილია საპროექტო მომსახურების ოთხი გამსხვილებული სტადია,
        პროექტის სრული ღირებულების წილის შესაბამისი სარეკომენდაციო
        პროცენტული მაჩვენებლებით:`,
    ``,
    `არქიტექტორმა დამკვეთს უნდა შეუქმნას ზუსტი წარმოდგენა, თუ რას
        მოიცავს არქიტექტურული მომსახურება ამა თუ იმ ეტაპზე, ხოლო
        დამატებითი სერვისების ანაზღაურება უნდა განისაზღვროს სამუშაოს
        დეტალური აღწერის შემდეგ მხარეებს შორის ურთიერთშეთანხმების
        საფუძველზე.`,
    `სტანდარტად უნდა განისაზღვროს, რომ ნებისმიერი სახელმწიფო
        გადასახადი, მოსაკრებელი, გამწვანების გადასახადი და სხვა, ასევე
        მშენებლობის ზედამხედველობის, მგზავრობის ხარჯები არის დამატებითი,
        არქიტექტორის მომსახურების ღირებულებაში არ შედის და დამკვეთის
        მოთხოვნის შემთხვაში ემატება მომსახურების ხელშეკრულებას.`,
  ];
  const service_list = [
    "კონცეფცია - 15%",
    "ესკიზური პროექტი - 30%",
    "სანებართვო დოკუმენტაცია - 30%",
    "მუშა დოკუმენტაცია - 25%",
  ];
  const calc_platform = [
    `არქიტექტურული პროექტირების ღირებულების საანგარიშო ფორმულა
        შემუშავებულ იქნა მსოფლიო გამოცდილების და საქართველოს პრაქტიკის
        გათვალისწინებით.`,
    `რაც უფრო მცირე ზომისაა პროექტი, მით უფრო მეტია მისი ერთი
        კვადრატული მეტრი ფართობის საპროექტო ღირებულება და შესაბამისად,
        კვადრატული მეტრის ღირებულება კლებულობს სამშენებლო ფართის ზრდასთან
        ერთად.`,
    `საანგარიშო ფორმულაში გამოკვეთილია ის ძირითადი ფაქტორები, რომლებიც
        განსაზღვრავენ არქიტექტურული პროექტის სირთულეს და გავლენას ახდენენ
        მომსახურების ღირებულებაზე.`,
    `თვითოეულ ფაქტორს - იქნება ეს შენობის სირთულე (კატეგორიების
        მიხედვით), მომსახურების ტიპი (ახალი, რეკონსტრუქცია, კულტურული
        მემკვიდრეობის უძრავი ძეგლი), საპროექტო არეალი (რეგულირების ზონები)
        - მინიჭებული აქვს შესაბამისი, ფასის მარეგულირებელი კოეფიციენტი:`,
    `გარდა ამისა, არსებობს ფასის განმსაზღვრელი ფაქტორი, რომლის დადგენაც
        იდივიდუალურად, არქიტექტორსა და პროექტზეა დამოკიდებული. აღნიშნული
        ფაქტორი საანგარიშო პლატფორმაზე მოიაზრება როგორც პირობითი
        კოეფიციენტი, რომელსაც არქიტექტორი ადგენს საკუთარი გამოცდილებისა და
        კვალიფიკაციის შესაბამისად.`,
    `საანგარიშო პლატფორმაზე სათანადო მონაცემების შეყვანის შემდეგ
        შესაძლებელია კომერციული წინადადების ავტომატური გენერირება
        (ჩამოტვირთვა pdf ფორმატში)`,
  ];
  const goal = [
    `საანგარიშო დაეხმარება არქიტექტორებს და პოტენციურ დამკვეთებს
        არქიტექტურული მომსახურების ღირებულების დადგენაში.`,
    `საანგარიშოს შექმნის ინიციატორები არიან საქართველოში მოქმედი
        პროფესიონალი არქიტექტორები.`,
    `საანგარიშო პლატფორმაზე მოცემული ფასები წარმოადგენს პროექტირების
        ღირებულების სარეკომენდაციო მინიმუმს.`,
    `არქიტექტორთა პროფესიული საზოგადოება იზიარებს დარგის რეგულირებისა
        და პროფესიური საქმიანობის ხარისხის ზრდის აუცილებლობას.`,
  ];

  return (
    <div className="container">
      <div className="burger-menu">
        <div className="burger-inner-wrap">
          <a href="/">
            <img
              src="./logo.png"
              width="150px"
              height="40px"
              className="logo"
              alt=""
            />
          </a>
          <div className="burger-buttons-wrap">
            <button className="the-button" onClick={changeLanguage}>
              {lang === "En" ? "Geo" : "En"}
            </button>
            <button className="burger-button" onClick={toggleMenu}>
              ☰
            </button>
          </div>
        </div>
        <nav className={isOpen ? "open" : ""}>
          <ul>
            <li>
              <Link to="/">{lang === "En" ? "Main" : "მთავარი"}</Link>
            </li>
            <li>
              <Link to="/Arch">
                {lang === "En" ? "Architecture" : "არქიტექტურა"}
              </Link>
            </li>
            <li>
              <Link to="/City">
                {lang === "En" ? "City Planning" : "ქალაქგეგმარება"}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="header">
        <a href="/">
          <img
            src="./logo.png"
            width="150px"
            height="40px"
            className="logo"
            alt=""
          />
        </a>
        <nav>
          <ul>
            <li>
              <Link to="/">{lang === "En" ? "Main" : "მთავარი"}</Link>
            </li>
            <li>
              <Link to="/Arch">
                {lang === "En" ? "Architecture" : "არქიტექტურა"}
              </Link>
            </li>
            <li>
              <Link to="/City">
                {lang === "En" ? "City Planning" : "ქალაქგეგმარება"}
              </Link>
            </li>
            <li>
              <button className="the-button" onClick={changeLanguage}>
                {lang === "En" ? "Geo" : "En"}
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="main-content">
        <div>
          {(lang === "En" ? titleEn : title).map((str, index) => (
            <h3 key={index}>{str}</h3>
          ))}
        </div>
        <div>
          <label className="descr">{lang === "En" ? descEn : desc}</label>
          <div className="wall-of-text">
            <p className="gray">{lang === "En" ? "Goals" : "მიზნები"}</p>
            <ul>
              {(lang === "En" ? goalEn : goal).map((str, index) => (
                <li key={index}>{str}</li>
              ))}
            </ul>
            <p className="gray">{lang === "En" ? "Introduction" : "შესავალი"}</p>
            {(lang === "En" ? introEn : intro).map((str, index) => (
              <React.Fragment key={index}>
                {str}
                {index !== intro.length - 1 && <br />}
                <br />
              </React.Fragment>
            ))}
            <p className="gray">{lang === "En" ? "Architectural Services" : "არქიტექტურული სერვისები"}</p>
            {(lang === "En" ? serviceEn : service)[0]}
            <br /> <br />
            <ol>
              {(lang === "En" ? service_listEn : service_list).map(
                (str, index) => (
                  <li key={index}>{str}</li>
                )
              )}
            </ol>
            <br /> <br />
            {(lang === "En" ? serviceEn : service)
              .slice(2)
              .map((str, index) => (
                <React.Fragment key={index}>
                  {str}
                  {index !== service.length - 1 && <br />}
                  <br />
                </React.Fragment>
              ))}
            <p className="gray">{lang === "En" ? "Estimating Platform" : "საანგარიშო პლატფორმა"}</p>
            {(lang === "En" ? calc_platformEn : calc_platform).map(
              (str, index) => (
                <React.Fragment key={index}>
                  {str}
                  {index !== calc_platform.length - 1 && <br />}
                  <br />
                </React.Fragment>
              )
            )}
          </div>
        </div>
      </div>
      <footer>
        <div></div>
        <div className="footer-line"></div>
        <div className="contact-info">
          <h2>{lang === "En" ? "Email" : "ელ.ფოსტა"}: info@forarchitects.ge</h2>
          <h2>
            {lang === "En" ? "Contact Number" : "საკონტაქტო ნომერი"}: (+995) 555
            18 00 08
          </h2>
        </div>
      </footer>
    </div>
  );
}

export default Main;
