import React, { Component } from "react";
import { Link } from "react-router-dom";
import html2pdf from "html2pdf.js";
import "./App.css";
import "./index.css";

const tableData = [
  {
    text1: "წინასაპროექტო კვლევა და კონცეფცია",
    text2: "15.0%",
    division: 20 / 3,
  },
  { text1: "ესკიზური პროექტი", text2: "30.0%", division: 10 / 3 },
  { text1: "სანებართვო დოკუმენტაცია", text2: "30.0%", division: 10 / 3 },
  { text1: "მუშა დოკუმენტაცია", text2: "25.0%", division: 4 },
];
const tableDataEn = [
  {
    text1: "Pre-design Research and Concept",
    text2: "15.0%",
    division: 20 / 3,
  },
  { text1: "Draft Project", text2: "30.0%", division: 10 / 3 },
  {
    text1: "Documentation needed for Permit",
    text2: "30.0%",
    division: 10 / 3,
  },
  {
    text1: "Documentation needed for Construction",
    text2: "25.0%",
    division: 4,
  },
];

class Arch extends Component {
  optionsMap = {
    Option1: ["მრავალფუნქციური კომპლექსი [IV]"],
    Option2: [
      "პანორამული გადასახედი [II]",
      "ფანჩატური, პერგოლა, ბუნგალო [II]",
      "პავილიონი  [III]",
      "კინოთეატრი [III]",
      "ახალგაზრდული ცენტრი [III]",
      "საგამოფენო დარბაზი [III]",
      "გასართობი ცენტრი [III]",
      "კლუბი, დისკოთეკა [III]",
      "საკონცერტო დარბაზი [IV]",
      "ბიბლიოთეკა და არქივი [IV]",
      "რადიოს და ტელევიზიის სტუდია [IV]",
      "საკულტო ობიექტები [IV]",
      "ვიზიტორთა ცენტრი [IV]",
      "მუზეუმი [V]",
      "საკონცერტო დარბაზები [V]",
      "თეატრი, აუდიტორია [V]",
    ],
    Option3: [
      "სამრეცხაო, ქიმწმენდა [I]",
      "სამკერვალო, სამხატვრო, სამჭედლო [II]",
      "მოტელი, კემპინგი [II]",
      "ჰოსტელი [III]",
      "აბანო [III]",
      "სასტუმროს ტიპის საცხოვრებელი, აპარტოტელი [III]",
      "სილამაზის სალონი, საპარიკმახერო [III]",
      "ფოსტა [III]",
      "მემორიალური საფლავი [IV]",
      "სასტუმრო [IV]",
    ],
    Option4: [
      "საბითუმო მაღაზია-საწყობი [II]",
      "სასადილო [II]",
      "მაღაზია [III]",
      "ბაზარი [III]",
      "კომერციული დანიშნულების შენობა [III]",
      "კაფე, ბარი [III]",
      "რესტორანი [III]",
      "სტუდენტური სასადილო [III]",
      "კომერციული სამზარეულო [III]",
      "სწრაფი კვება [III]",
      "სავაჭრო პავილიონი [III]",
      "სავაჭრო ცენტრი [IV]",
    ],
    Option5: [
      "ოფისი [III]",
      "სახანძრო, სამაშველო ობიექტი [III]",
      "პოლიცია და პროკურატურა [III]",
      "საბაჟო [III]",
      "სასამართლო [III]",
      "საელჩო [IV]",
      "პენიტენციალური დაწესებულებულება [IV]",
      "ბანკი [IV]",
      "სახელმწიფო და ადმინისტრაციული დაწესებულება [IV]",
    ],
    Option6: [
      "მრავალბინიანი საცხოვრებელი სახლი  [III]",
      "კომუნალური საცხოვრებელი სახლი [III]",
      "ხანდაზმულთა თავშესაფარი [III]",
      "ინდივიდუალური საცხოვრებელი სახლი [IV]",
      "ტერასული მრავალბინიანი საცხოვრებელი სახლი [IV]",
      "რთულ რელიეფზე მდებარე საცხოვრებელი სახლი [IV]",
    ],
    Option7: [
      "დაწყებითი სკოლა [III]",
      "საბავშვო ბაღი [III]",
      "საშუალო სკოლა [IV]",
      "საგანმანათლებლო ცენტრი [IV]",
      "ტექნიკური სასწავლებელი [IV]",
      "უმაღლესი სასწავლებელი და უნივერსიტეტი [IV]",
      "სალექციო დარბაზი [IV]",
      "სასწავლო  დაწესებულება [IV]",
      "ლაბორატორია [IV]",
      "სამეცნიერო და კვლევითი შენობა [IV]",
    ],
    Option8: [
      "პირველადი დახმარების პუნქტი  [II]",
      "სამედიცინო დაწესებულება [III]",
      "აფთიაქი [III]",
      "ამბულატორიული დაწესებულება, პოლიკლინიკა [III]",
      "სარეაბილიტაციო დაწესებულება, დისპანსერი [IV]",
      "სპეციალიზებული საავადმყოფო [IV]",
      "სპა [IV]",
      "პანსიონატი და სანატორიუმი [IV]",
      "საავადმყოფო [V]",
      "საუნივერსიტეტო კლინიკა [V]",
    ],
    Option9: [
      "სპორტული მოედანი [II]",
      "ერთ სართულიანი სპორტული დარბაზი  [II]",
      "სპორტული დარბაზები (ორ და მეტ სართულიანი) [III]",
      "საცურაო აუზი [IV]",
      "სტადიონი [IV]",
      "მრავალფუნქციური დარბაზები კულტურული და სპორტული დანიშნულების [IV]",
      "ავტოდრომი [IV]",
      "ველოტრეკი [IV]",
      "იახტკლუბი [IV]",
      "იპოდრომი [IV]",
      "საციგურაო დარბაზი [IV]",
      "მრავალფუქნციური სპორტული კომპლექსი [IV]",
    ],
    Option10: [
      "ავტოსამრეცხაო [I]",
      "ავტოსერვისი [II]",
      "ერთსართულიანი და მრავალსართულიანი ავტოსადგომი [II]",
      "ბენზინის, დიზელის, გაზის ელექტრო გასამართი სადგური [III]",
      "ავტოსადგური, რკინიგზის სადგური [IV]",
      "სატრანსპორტო ინფრასტრუქტურის ობიექტები [IV]",
      "საზღვაო პორტი [V]",
      "აეროპორტი და აეროდრომი [V]",
    ],
    Option11: [
      "დროებითი გადაუდებელი საცხოვრებელი და ყაზარმა [I]",
      "გასახდელები [I]",
      "სასაწყობე შენობები [I]",
      "მარტივი სასოფლო-სამეურნეო შენობები [I]",
      "სათბური,  ორანჟერა [II]",
      "მარტივი სასოფლო-სამეურნეო და შენობები [III]",
      "სამხრეთ ქვემოთან და ქვემოთან წყალობით შემოქმედება [IV]",
      "უცხო კურორტები, საუნი [IV]",
      "სამხრეთ და დასავლეთ ქვემოთან და ქვემოთან წყალობით შემოქმედება [V]",
      "საწყენებლის წამონათქვა [V]",
      "სასალვერო და ხარისხის შემოქმედება [V]",
    ],
  };

  optionLabels = {
    Option1: "ორი ან მეტი ფუნქციის ობიექტები",
    Option2: "კულტურის და დასვენების ობიექტები",
    Option3: "სასტუმროები, საყოფაცხოვრებო მომსახურების ობიექტები",
    Option4: "სავაჭრო და კვების ობიექტები",
    Option5: "ოფისები, ადმინისტრაციული და საქმიანი ობიექტები",
    Option6: "საცხოვრებელი ობიექტები",
    Option7: "სამეცნიერო და საგანმანათლებლო ობიექტები",
    Option8: "სამედიცინო და ჯანმრთელობის ობიექტები",
    Option9: "სპორტულ-გამაჯანსაღებელი ობიექტები",
    Option10: "სატრანსპორტო ინფრასტრუქტურის ობიექტები",
    Option11: "დროებითი, სასოფლო სამეურნეო, საწარმოო, დამხმარე ობიექტები",
  };

  optionsMapEn = {
    Option1: ["Multifunctional complex [IV]"],
    Option2: [
      "Panoramic View [II]",
      "Fanchaturi, Pergola, Bungalow [II]",
      "Pavilion [III]",
      "Cinema [III]",
      "Youth Center [III]",
      "Exhibition Hall [III]",
      "Entertainment Center [III]",
      "Club, Disco [III]",
      "Concert Hall [IV]",
      "Library and Archive [IV]",
      "Radio and Television Studio [IV]",
      "Cult Objects [IV]",
      "Visitor Center [IV]",
      "Museum [V]",
      "Concert Halls [V]",
      "Theatre, Auditorium [V]",
    ],
    Option3: [
      "Laundry, Dry cleaning [I]",
      "Sewing, Art, Forge [II]",
      "Motel, Camping [II]",
      "Hostel [III]",
      "Bath [III]",
      "Hotel type accommodation, aparthotel [III]",
      "Beauty salon, barbershop [III]",
      "Mail [III]",
      "Memorial Tomb [IV]",
      "Hotel [IV]",
    ],
    Option4: [
      "Wholesale store-warehouse [II]",
      "Dining Room [II]",
      "Shop [III]",
      "Market [III]",
      "Commercial purpose building [III]",
      "Cafe, Bar [III]",
      "Restaurant [III]",
      "Student Canteen [III]",
      "Commercial Kitchen [III]",
      "Fast Food [III]",
      "Trade Pavilion [III]",
      "Shopping Center [IV]",
    ],
    Option5: [
      "Office [III]",
      "Fire, rescue facility [III]",
      "Police and Prosecutor's Office [III]",
      "Customs [III]",
      "Court [III]",
      "Embassy [IV]",
      "Penitential Institution [IV]",
      "Bank [IV]",
      "State and administrative institution [IV]",
    ],
    Option6: [
      "Multi-apartment residential building [III]",
      "Communal housing [III]",
      "Nursing Home [III]",
      "Individual residential house [IV]",
      "Terrace apartment building [IV]",
      "Dwelling house on difficult terrain [IV]",
    ],
    Option7: [
      "Elementary School [III]",
      "Kindergarten [III]",
      "Secondary School [IV]",
      "Educational Center [IV]",
      "Technical school [IV]",
      "Higher education and university [IV]",
      "Lecture Hall [IV]",
      "Educational institution [IV]",
      "Lab [IV]",
      "Scientific and Research Building [IV]",
    ],
    Option8: [
      "First aid point [II]",
      "Medical facility [III]",
      "Pharmacy [III]",
      "Outpatient institution, polyclinic [III]",
      "Rehabilitation facility, dispensary [IV]",
      "Specialized Hospital [IV]",
      "Spa [IV]",
      "Boarding and sanatorium [IV]",
      "Hospital [V]",
      "University Clinic [V]",
    ],
    Option9: [
      "Sports Field [II]",
      "One Floor Gym [II]",
      "Sports halls (two or more floors) [III]",
      "Swimming Pool [IV]",
      "Stadium [IV]",
      "Multifunctional halls for cultural and sports purpose [IV]",
      "Autodrome [IV]",
      "Cycling [IV]",
      "Yacht Club [IV]",
      "Hippodrome [IV]",
      "Skating Hall [IV]",
      "Multifunctional sports complex [IV]",
    ],
    Option10: [
      "Car wash [I]",
      "Autoservice [II]",
      "Single-storey and multi-storey car park [II]",
      "Gasoline, diesel, gas electric station [III]",
      "Bus station, railway station [IV]",
      "Transport infrastructure facilities [IV]",
      "Seaport [V]",
      "Airport and Aerodrome [V]",
    ],
    Option11: [
      "Temporary Emergency Housing and Barracks [I]",
      "Dressing Rooms [I]",
      "Storage Buildings [I]",
      "Simple Agricultural Buildings [I]",
      "Greenhouse, Orangery [II]",
      "Simple Farming and Buildings [III]",
      "Creation from the South and the Beneath [IV]",
      "Foreign resorts, sauna [IV]",
      "From the south and the west below and below the creation [V]",
      "The Proposition of Regret [V]",
      "Salvage and Quality Creation [V]",
    ],
  };

  optionLabelsEn = {
    Option1: "Objects of two or more functions",
    Option2: "Culture and recreation facilities",
    Option3: "hotels, household service facilities",
    Option4: "Shopping and catering facilities",
    Option5: "Offices, administrative and business facilities",
    Option6: "Residential facilities",
    Option7: "Scientific and educational facilities",
    Option8: "Medical and health facilities",
    Option9: "Sports and recreation facilities",
    Option10: "Transport infrastructure facilities",
    Option11: "Temporary, agricultural, production, support facilities",
  };

  commonPhrases = {
    chooseOption: "- - - - -",
    subOpt: "- - - - -",
    selectMainOption: "საპროექტო შენობის ფუნქციური დანიშნულება:",
    selectSubOption: "საპროექტო შენობის დასახელება:",
    numberEnter2: "შეიყვანეთ შენობის საანგარიშო ფართობი",
    numberEnter1: "შეიყვანეთ პირობითი კოეფიციენტი",
    cost: "არქიტექტურული პროექტის ღირებულება დღგ-ს გარეშე:",
    totalCost: "არქიტექტურული პროექტის ღირებულება დღგ-ს ჩათვლით:",
    gdp: "დღგ [ 18 % ]",
    onePrice: "ერთი კვ. მეტრი ფართობის ღირებულება დღგ-ს გარეშე",
    page1: "მთავარი",
    page2: "არქიტექტურა",
    page3: "ქალაქგეგმარება",
    option1: "ახალი მშენებლობა",
    option2: "რეკონსტრუქცია [ X 1.1 ]",
    option2R: "რეკონსტრუქცია",
    checkbox1: "ისტორიული რეგულირების ზონა",
    checkbox2: "კულტურული მემკვიდრეობის არქიტექტურული ძეგლი",
    dolari: "დოლარი",
    categoryLabel: "კატეგორია და კოეფიციენტი:",
    USD: "USD",
    agwera:
      "არქიტექტურული პროექტირების ღირებულების საანგარიშო შეიქმნა საქართველოში მოქმედი არქიტექტორების მიერ, იმისთვის რომ ხელი შეუწყოს არქიტექტურული საქმიანობის გამჭვირვალობას, მომსახურების ხარისხის გაზრდასა და პროფესიის მიმართ ანგარიშვალდებულებას. გამომთვლელი შექმნილია საერთაშორისო გამოცდილებაზე დაყრდნობით და სხვადასხვა ქვეყნებში მოქმედი არქიტექტურული საქმიანობის მარეგულირებელი სტანდარტების გათვალისწინებით",
    ganmart1:
      "შენიშვნა: პირობითი კოეფიციენტი წარმოადგენს არქიტექტორის მიერ ინდივიდუალურად განსაზღვრულ მაჩვენებელს, რომელსაც არქიტექტორი ადგენს საკუთარი გამოცდილების და კვალიფიკაციის შესაბამისად",
    ganmart2:
      "შენიშვნა:საპროექტო საანგარიშო ფართობი გამოითვლება ყველა სართულის ფილის გარე კონტურზე, მიწისქვეშა სართულებისა და საზაფხულო სათავსების, ტერასების ჩათვლით (გარდა ბრტყელი გადახურვებისა)",
    descText:
      "შენიშვნა: შენობის არქიტექტურული პროექტირების ღირებულებაზე გავლენას ახდენს საპროექტო ობიექტის სირთულე და პროექტის კომპლექსურობა. რაც უფრო მაღალი სირთულის კატეგორიისაა ობიექტი, მით მაღალია საპროექტო მომსახურების ღირებულება",
    botlaneWarning:
      "შენიშვნა: არქიტექტურული მომსახურების ღირებულება იყოფა პირობით პროცენტულ მაჩვენენბლებად, რაც დაინტერესებულ მხარეებს აძლევთ შესაძლებლობას აირჩიონ მომსახურების ეტაპები. ეტაპების ფასები მოცემულია დღგ-ს გარეშე.",
    placeHolder1:
      "ჩაწერეთ კომპანიის ან არქიტექტორის სახელი, ან სხვა დამატებითი ინფორმაცია",
    placeHolder2: "მიუთითეთ პროექტის დასახელება, ან სხვა დამატებითი ინფორმაცია",
    placeHolder3: "შეიყვანეთ დამატებითი ინფორმაცია ან შენიშვნა",
    span1:
      "პროექტის საბოლოო ფასი წარმოადგენს მხოლოდ არქიტექტურული პროექტის მომზადების ღირებულებას. პროექტის საბოლოო ფასში არ შედის საავტორო ზედამხედველობა, ინტერიერის დიზაინის, არსებული შენობის ან/და ტერიტორიის აზომვითი სამუშაოების, კონსტრუქციული და საინჟინრო პროექტების და სხვა მომიჯნავე სპეციალისტების მომსახურების ღირებულებები. პროექტის საბოლოო ფასი არ მოიცავს ადმინისტრაციულ ორგანოებში ინფორმაციის მოძიებისთვის, გამოთხოვისთვის გათვალისწინებულ გადასახადებსა და მოსაკრებლების ღირებულებას.",
    testText:
      "საიტი მუშაობს სატესტო რეჟიმში. გთხოვთ, შენიშვნები და წინადადებები მოგვწეროთ ქვემოთ მითითებულ ელექტრონული ფოსტის მისამართზე",
  };

  commonPhrasesEn = {
    chooseOption: "- - - - -",
    subOpt: "- - - - -",
    selectMainOption: "Function of the project:",
    selectSubOption: "Name of the Project - Title:",
    numberEnter2: "Enter the area of the building",
    numberEnter1: "Enter conditional coefficient",
    cost: "Cost of architectural project without VAT:",
    totalCost: "Cost of architectural project - VAT included:",
    gdp: "VAT [ 18 % ]",
    onePrice: "Price per square meter without VAT",
    page1: "Main",
    page2: "Architecture",
    page3: "Urban Planning",
    option1: "New Construction",
    option2: "Reconstruction [ X 1.1 ]",
    option2R: "Reconstruction",
    checkbox1: "Historic regulation area",
    checkbox2: "Architectural monument of cultural heritage",
    dolari: "Dollar",
    categoryLabel: "Category and Coefficient:",
    USD: "USD",
    agwera:
      "The Web platform for Calculating costs of architectural Services was created by architects operating in Georgia, in order to promote the transparency of architectural activity, increase the quality of service and accountability to the profession. The calculator was created based on international experience and taking into account the regulatory standards of architectural activity in different countries.",
    ganmart1:
      "Note: the provisory coefficient is an indicator determined individually by the architect, which the architect sets in accordance with his own experience and qualifications.",
    ganmart2:
      "Note: the design area is calculated on the outer contour of all floor slabs, including basements and summer sheds, terraces (except for flat roofs)",
    descText:
      "Note: The cost of the architectural design of the building is influenced by the complexity of the design and the complexity of the project. The higher the complexity design, the higher the cost of services",
    botlaneWarning:
      "Note: The cost of architectural services is divided into conditional percentages, giving interested parties the opportunity to choose the stages of the service. The prices of the stages are given without VAT.",
    placeHolder1:
      "Enter the name of the company or architect, or other additional information",
    placeHolder2:
      "Indicate the name of the project or other additional information",
    placeHolder3: "Enter additional information or a note",
    span1:
      "The final price of the project represents only the cost architectural project. The final price of the project does not include author's supervision, interior design, measurement works for existing buildings and/or area, Static, engineering projects and other related specialist services. The final price of the project does not include the costs or expenses in administrative bodies.",
    testText:
      "The site is in test mode. Please send us your comments and suggestions to the email address below",
  };

  state = {
    selectedOption: "",
    selectedSubOption: "",
    selectedCategory: -1,
    numberInput1: "1",
    numberInput2: null,
    calculatedCost: 0,
    checkedCost: 0,
    gdpDisabled: true,
    selectedOptionChanged: false,
    checkbox1Disabled: false,
    checkbox2Disabled: false,
    tableCheckbox1Checked: true,
    tableCheckbox2Checked: true,
    tableCheckbox3Checked: true,
    tableCheckbox4Checked: true,
    botInput1: "",
    botInput2: "",
    botInput3: "",
    isOpen: false,
    lang: localStorage.getItem("selectedLanguage") || "Geo",
  };

  toggleMenu = () => {
    // Toggle the isOpen state
    this.setState({ isOpen: !this.state.isOpen });
  };
  toggleCheckbox1 = () => {
    this.setState(
      (prevState) => ({
        checkbox1Checked: !prevState.checkbox1Checked,
      }),
      this.handleNumberInput
    );
  };

  toggleCheckbox2 = () => {
    this.setState(
      (prevState) => ({
        checkbox2Checked: !prevState.checkbox2Checked,
      }),
      this.handleNumberInput
    );
  };

  handleCheckboxChange = (checkboxIndex) => {
    const newState = { ...this.state };
    newState[`tableCheckbox${checkboxIndex}Checked`] =
      !newState[`tableCheckbox${checkboxIndex}Checked`];
    newState.checkedCost = this.recalculateCosts(newState);
    this.setState(newState);
  };
  recalculateCosts = (newState) => {
    const total = newState.calculatedCost;
    const tData = this.state.lang === "En" ? tableDataEn : tableData;
    let newCost = 0;
    for (let i = 1; i < 5; i++) {
      if (newState[`tableCheckbox${i}Checked`]) {
        newCost += total / tData[i - 1]["division"];
      }
    }
    return newCost;
  };
  handleOptionChange = (event) => {
    const value = event.target.value;
    this.setState({
      selectedOption: value,
      selectedSubOption: "",
      selectedCategory: -1,
      checkedCost: 0,
      calculatedCost: 0,
    });
  };

  handleSubOptionChange = (event) => {
    this.setState({ selectedSubOption: event.target.value }, () => {
      this.handleNumberInput();
    });
  };

  convertCategoryToCategoryCoefficient(str) {
    const match = str.match(/\[([IV]+)\]/);
    if (!match) return null;

    const map = {
      I: 1.0,
      II: 1.3,
      III: 1.6,
      IV: 1.9,
      V: 2.2,
    };

    return map[match[1]] || -1;
  }

  handleKeyDown2 = (event) => {
    if (
      event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key === "."
    ) {
      event.preventDefault();
    }
  };

  handleNumberInput1 = (event) => {
    let { value } = event.target;
    if (value === "") {
      this.setState({ numberInput1: "" }, this.handleNumberInput);
      return;
    }
    if (/^[1-9]\d*([.,]\d*)?$/.test(value)) {
      this.setState({ numberInput1: value }, this.handleNumberInput);
    }
  };
  handleNumberInput2 = (event) => {
    const { value } = event.target;
    if (/^[1-9]\d*$/.test(value) || value === "") {
      this.setState({ numberInput2: value }, this.handleNumberInput);
    }
  };
  handleNumberInput = () => {
    function logBase(val, base) {
      return Math.log(val) / Math.log(base);
    }

    const {
      numberInput1,
      numberInput2,
      selectedOptionClick,
      selectedSubOption,
      checkbox1Checked,
      checkbox2Checked,
    } = this.state;

    const multiplierMap = {
      option1: 1.0,
      option2: 1.1,
    };

    const parsedValue1 = parseFloat(numberInput1);
    const parsedValue2 = parseFloat(numberInput2);

    if (isNaN(parsedValue1) || isNaN(parsedValue2)) {
      this.setState({ calculatedCost: 0, checkedCost: 0 });
    } else {
      const selectedMultiplier = multiplierMap[selectedOptionClick];

      let inputValue =
        5 +
        12 * (1 / logBase(parsedValue2, 25000) - logBase(parsedValue2, 25000));

      if (inputValue < 5) {
        inputValue = 5;
      }

      const cost = Math.round(
        parsedValue1 *
          parsedValue2 *
          inputValue *
          selectedMultiplier *
          this.convertCategoryToCategoryCoefficient(selectedSubOption) *
          (checkbox1Checked ? 1.2 : 1.0) *
          (checkbox2Checked ? 1.4 : 1.0)
      );

      const newState = { ...this.state };
      newState.calculatedCost = cost;

      let cc = this.recalculateCosts(newState);
      newState.checkedCost = cc;

      this.setState(newState);
    }
  };

  handleCategorySwitch = (str) => {
    let option;
    const match = str.match(/\[([IV]+)\]/);
    if (!match) return null;
    switch (match[1]) {
      case "I":
        option =
          this.state.lang === "En"
            ? "I Category|[ X 1.0 ]"
            : "I კატეგორია|[ X 1.0 ]";
        break;
      case "II":
        option =
          this.state.lang === "En"
            ? "II Category|[ X 1.3 ]"
            : "II კატეგორია|[ X 1.3 ]";
        break;
      case "III":
        option =
          this.state.lang === "En"
            ? "III Category|[ X 1.6 ]"
            : "III კატეგორია|[ X 1.6 ]";
        break;
      case "IV":
        option =
          this.state.lang === "En"
            ? "IV Category|[ X 1.9 ]"
            : "IV კატეგორია|[ X 1.9 ]";
        break;
      case "V":
        option =
          this.state.lang === "En"
            ? "V Category|[ X 2.2 ]"
            : "V კატეგორია|[ X 2.2 ]";
        break;
    }
    return option;
  };

  toggleGDP = () => {
    this.setState((prevState) => ({
      gdpDisabled: !prevState.gdpDisabled,
    }));
  };

  handleOptionChangeButton = (option) => {
    if (this.state.selectedOption !== option) {
      this.setState(
        {
          selectedOptionClick: option,
          selectedOptionChanged: true,
        },
        () => {
          this.handleNumberInput();
        }
      );
    }
  };

  handleInputChange = (event, inputNumber) => {
    const inputValue = event.target.value;
    if (inputNumber === 1) {
      this.setState({ botInput1: inputValue });
    } else if (inputNumber === 2) {
      this.setState({ botInput2: inputValue });
    } else if (inputNumber === 3) {
      this.setState({ botInput3: inputValue });
    }
  };
  checkMandatory = (state) => {
    return (
      state.selectedOption &&
      state.selectedSubOption &&
      state.numberInput1 >= 1 &&
      state.numberInput2 >= 1
    );
  };
  generatePDF = () => {
    const newState = { ...this.state };
    const calculatedCost = newState.calculatedCost;
    const checkedCost = Math.round(newState.checkedCost);
    const gdpDisabled = newState.gdpDisabled;
    const gdp = Math.round((checkedCost * 18) / 100);
    const total = Math.round(checkedCost + (gdpDisabled ? gdp : 0));
    const v1 = newState.numberInput1;
    const v2 = newState.numberInput2;
    const pdfContent = document.createElement("div");
    const subP = newState.selectedSubOption.split("[");
    const onePrice = Math.round(isNaN(checkedCost / v2) ? 0 : checkedCost / v2);

    if (!this.checkMandatory(newState)) {
      alert("Please fill all mandatory fields");
      return;
    }

    // some CSS
    const smallFont = "12px";
    const largeFont = "14px";

    pdfContent.innerHTML = `
        <html lang="en" style ="height: 1100px;">
            <head>
                <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
             <title>PDF</title>
         <style>
    :root{
      --large-font-size: 14px;
      --small-font-size: 12px;
    }
    body {
      font-size:${smallFont};
      margin: 8px;
    }
    h1 {
      font-size: 14px;
      color: white;
      background-color: gray;
      text-align: end;
      padding: 30px 60px;
    }
    .bordered {
      border: 1px solid black;
    }
    .names-wrap{
      padding: 8px;
      margin-left: auto;
      display: grid;
      width: 60%;
      grid-template-columns: 60% 40%;
      gap: 10px;
      justify-content: right;
    }
    .names-wrap > .label {
      width: calc(100% - 16px);
      padding: 8px;
    }
    .names-wrap > .name {
      width: calc(100% - 10px);
      text-align: right;
      word-wrap: break-word;
      padding: 3px 5px;
    }
    .info-title{
      margin: 10px 0 20px 0;
      padding: 20px 15px;
      font-size: ${largeFont};
    }
    .main-wrap{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .grid-1, .grid-2,.grid-3,.grid-4 {
      display: grid;
    }
    .grid-1, .grid-2,.grid-3,.grid-4 > span{
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
    .grid-1{
      grid-template-columns: 1fr 1fr;
    }
    .grid-2{
      grid-template-columns: 65% 35%;
    }
    .grid-3{
      grid-template-columns: 65% 15% 20%;
    }
    .grid-4{
      grid-template-columns: 65% 25% 10%;
    }
    .left, .right {
      display: flex;
      align-items: center;
      padding:5px;
      border-top: 1px solid black;
      border-left: 1px solid black;
    }
    .left{
      justify-content: left;
    }
    .right{
      justify-content: right;
    }
    .smol{
      width: 85%;
    }
    .bottom-info{
      font-size: ${largeFont};
      display: block;
      text-align: start;
      margin: 15px 0;
    }
    .bottom-info > * {
      font-size: ${smallFont};
      margin-top: 5px;
      padding: 5px 10px;
    }
    .date{
      text-align: end;
      margin-top: 60px;
    }
    .unfilled {
        display: none;
    }
  </style>
</head>
<body>
  <h1>
    კომერციული შეთავაზება
  </h1>

  <main>
    <div class="names-wrap">
      <div class="label ${this.state.botInput1 ? "" : "unfilled"}">
        კომპანიის ან არქიტექტორის სახელი:
      </div>
      <div class="name bordered ${this.state.botInput1 ? "" : "unfilled"}">${
      this.state.botInput1
    }</div>
      <div class="label ${this.state.botInput2 ? "" : "unfilled"}">
        პროექტის დასახელება:
      </div>
      <div class="name bordered ${this.state.botInput2 ? "" : "unfilled"}">${
      this.state.botInput2
    }</div>
    </div>
    <div class="info-title bordered">${
      this.state.selectedOptionClick === "option1"
        ? this.commonPhrases.option1
        : this.commonPhrases.option2R
    }</div>

    <div class="main-wrap">
      <div class="grid-1">
        <span class="left">
          საპროექტო შენობის ფუნქციური დანიშნულება:
        </span>
        <span class="right">${
          this.optionLabels[this.state.selectedOption]
        }</span>
        <span class="left">
          საპროექტო შენობის დასახელება:
        </span>
        <span class="right">${subP[0]}</span>
      </div>

      <div class="grid-2">
        <span class="left">კატეგორია და კოეფიციენტი</span>
        <span class="right">${this.handleCategorySwitch(
          this.state.selectedSubOption
        )}</span>
        <span class="left">ისტორიული რეგულირების ზონა</span>
        <span class="right">${
          this.state.checkbox1Checked ? "კი X1.2" : "არა X1.0"
        }</span>
        <span class="left">კულტურული მემკვიდრეობის არქიტექტურული ძეგლი</span>
        <span class="right">${
          this.state.checkbox2Checked ? "კი X1.4" : "არა X1.0"
        }</span>
      </div>

      <div class="grid-2">
        <span class="left">გამოცდილების და კვალიფიკაციის პირობითი კოეფიციენტი</span>
        <span class="right">${v1}</span>
      </div>

      <div class="grid-2">
        <span class="left">საპროექტო შენობის საანგარიშო ფართობი</span>
        <span class="right">${v2}</span>
      </div>

      <div class="grid-3 smol">
        <span class="left">წინასაპროექტო კვლევა და კონცეფცია</span>
        <span class="right">15.0 %</span>
        <span class="right">${
          this.state["tableCheckbox1Checked"]
            ? Math.round(calculatedCost / tableData[0]["division"])
            : "---"
        }</span>
        <span class="left">ესკიზური პროექტი</span>
        <span class="right">30.0 %</span>
        <span class="right">${
          this.state["tableCheckbox2Checked"]
            ? Math.round(calculatedCost / tableData[1]["division"])
            : "---"
        }</span>
        <span class="left">სანებართვო დოკუმენტაცია</span>
        <span class="right">30.0 %</span>
        <span class="right">${
          this.state["tableCheckbox3Checked"]
            ? Math.round(calculatedCost / tableData[2]["division"])
            : "---"
        }</span>
        <span class="left">მუშა დოკუმენტაცია</span>
        <span class="right">25.0 %</span>
        <span class="right">${
          this.state["tableCheckbox4Checked"]
            ? Math.round(calculatedCost / tableData[3]["division"])
            : "---"
        }
        </span>
      </div>


      <div class="grid-4">
        <span class="left">ერთი კვ. მეტრი ფართობის ღირებულება დღგ-ს გარეშე</span>
        <span class="right">${onePrice}</span>
        <span class="right">USD</span>
        <span class="left">არქიტექტურული პროექტის ღირებულება დღგ-ს გარეშე</span>
        <span class="right">${checkedCost}</span>
        <span class="right">USD</span>
        <span class="left">დღგ</span>
        <span class="right">${gdpDisabled ? gdp : "---"}</span>
        <span class="right">USD</span>
        <span class="left">არქიტექტურული პროექტის ღირებულება დღგ-ს ჩათვლით</span>
        <span class="right">${total}</span>
        <span class="right">USD</span>
      </div>

      <div class="bottom-info ${this.state.botInput3 ? "" : "unfilled"}">
        დამატებითი ინფორმაცია და შენიშვნები:
        <div class="bordered">
          ${this.state.botInput3}
        </div>
      </div>
    </div>
    <div class="date">
      ${new Date().toLocaleDateString(undefined, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })}
    </div>
  </main>
</body>
</html>
`;
    html2pdf()
      .set({
        html2canvas: { scale: 4 },
        pageBreak: { mode: "css" },
        margin: 0.2,
        filename: "document.pdf",
        jsPDF: { unit: "in", format: [11.7, 8.3], orientation: "portrait" },
      })
      .from(pdfContent)
      .save();
  };

  generatePDFeng = () => {
    const newState = { ...this.state };
    const calculatedCost = newState.calculatedCost;
    const checkedCost = Math.round(newState.checkedCost);
    const gdpDisabled = newState.gdpDisabled;
    const gdp = Math.round((checkedCost * 18) / 100);
    const total = Math.round(checkedCost + (gdpDisabled ? gdp : 0));
    const v1 = newState.numberInput1;
    const v2 = newState.numberInput2;
    const pdfContent = document.createElement("div");
    const subP = newState.selectedSubOption.split("[");
    const onePrice = Math.round(isNaN(checkedCost / v2) ? 0 : checkedCost / v2);

    if (!this.checkMandatory(newState)) {
      alert("Please fill all mandatory fields");
      return;
    }

    // some CSS
    const smallFont = "12px";
    const largeFont = "14px";

    pdfContent.innerHTML = `
        <html lang="en" style ="height: 1100px;">
            <head>
                <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
             <title>PDF</title>
         <style>
    :root{
      --large-font-size: 14px;
      --small-font-size: 12px;
    }
    body {
      font-size:${smallFont};
      margin: 8px;
    }
    h1 {
      font-size: 14px;
      color: white;
      background-color: gray;
      text-align: end;
      padding: 30px 60px;
    }
    .bordered {
      border: 1px solid black;
    }
    .names-wrap{
      padding: 8px;
      margin-left: auto;
      display: grid;
      width: 60%;
      grid-template-columns: 60% 40%;
      gap: 10px;
      justify-content: right;
    }
    .names-wrap > .label {
      width: calc(100% - 16px);
      padding: 8px;
    }
    .names-wrap > .name {
      width: calc(100% - 10px);
      text-align: right;
      word-wrap: break-word;
      padding: 3px 5px;
    }
    .info-title{
      margin: 10px 0 20px 0;
      padding: 20px 15px;
      font-size: ${largeFont};
    }
    .main-wrap{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .grid-1, .grid-2,.grid-3,.grid-4 {
      display: grid;
    }
    .grid-1, .grid-2,.grid-3,.grid-4 > span{
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
    .grid-1{
      grid-template-columns: 1fr 1fr;
    }
    .grid-2{
      grid-template-columns: 65% 35%;
    }
    .grid-3{
      grid-template-columns: 65% 15% 20%;
    }
    .grid-4{
      grid-template-columns: 65% 25% 10%;
    }
    .left, .right {
      display: flex;
      align-items: center;
      padding:5px;
      border-top: 1px solid black;
      border-left: 1px solid black;
    }
    .left{
      justify-content: left;
    }
    .right{
      justify-content: right;
    }
    .smol{
      width: 85%;
    }
    .bottom-info{
      font-size: ${largeFont};
      display: block;
      text-align: start;
      margin: 15px 0;
    }
    .bottom-info > * {
      font-size: ${smallFont};
      margin-top: 5px;
      padding: 5px 10px;
    }
    .date{
      text-align: end;
      margin-top: 60px;
    }
    .unfilled {
        display: none;
    }
  </style>
</head>
<body>
  <h1>
    Commercial offer
  </h1>

  <main>
    <div class="names-wrap">
      <div class="label ${this.state.botInput1 ? "" : "unfilled"}">
        Company or Architect Name:
      </div>
      <div class="name bordered ${this.state.botInput1 ? "" : "unfilled"}">${
      this.state.botInput1
    }</div>
      <div class="label ${this.state.botInput2 ? "" : "unfilled"}">
        Project Name:
      </div>
      <div class="name bordered ${this.state.botInput2 ? "" : "unfilled"}">${
      this.state.botInput2
    }</div>
    </div>
    <div class="info-title bordered">${
      this.state.selectedOptionClick === "option1"
        ? this.commonPhrasesEn.option1
        : this.commonPhrasesEn.option2R
    }</div>

    <div class="main-wrap">
      <div class="grid-1">
        <span class="left">
          Function of the project:
        </span>
        <span class="right">${
          this.optionLabelsEn[this.state.selectedOption]
        }</span>
        <span class="left">
          Name of the Project - Title:
        </span>
        <span class="right">${subP[0]}</span>
      </div>

      <div class="grid-2">
        <span class="left">Category and Coefficient:</span>
        <span class="right">${this.handleCategorySwitch(
          this.state.selectedSubOption
        )}</span>
        <span class="left">Historic regulation area</span>
        <span class="right">${
          this.state.checkbox1Checked ? "Yes | X1.2" : "No | X1.0"
        }</span>
        <span class="left">Architectural monument of cultural heritage</span>
        <span class="right">${
          this.state.checkbox2Checked ? "Yes | X1.4" : "No | X1.0"
        }</span>
      </div>

      <div class="grid-2">
        <span class="left">Provisory coefficient of experience and qualifications</span>
        <span class="right">${v1}</span>
      </div>

      <div class="grid-2">
        <span class="left">Area of project building</span>
        <span class="right">${v2}</span>
      </div>

      <div class="grid-3 smol">
        <span class="left">Pre-design Research and Concept</span>
        <span class="right">15.0 %</span>
        <span class="right">${
          this.state["tableCheckbox1Checked"]
            ? Math.round(calculatedCost / tableData[0]["division"])
            : "---"
        }</span>
        <span class="left">Draft Project</span>
        <span class="right">30.0 %</span>
        <span class="right">${
          this.state["tableCheckbox2Checked"]
            ? Math.round(calculatedCost / tableData[1]["division"])
            : "---"
        }</span>
        <span class="left">Documentation needed for Permit</span>
        <span class="right">30.0 %</span>
        <span class="right">${
          this.state["tableCheckbox3Checked"]
            ? Math.round(calculatedCost / tableData[2]["division"])
            : "---"
        }</span>
        <span class="left">Documentation needed for Construction</span>
        <span class="right">25.0 %</span>
        <span class="right">${
          this.state["tableCheckbox4Checked"]
            ? Math.round(calculatedCost / tableData[3]["division"])
            : "---"
        }
        </span>
      </div>


      <div class="grid-4">
        <span class="left">Price per square meter without VAT</span>
        <span class="right">${onePrice}</span>
        <span class="right">USD</span>
        <span class="left">Cost of architectural project without VAT:</span>
        <span class="right">${checkedCost}</span>
        <span class="right">USD</span>
        <span class="left">VAT</span>
        <span class="right">${gdpDisabled ? gdp : "---"}</span>
        <span class="right">USD</span>
        <span class="left">Cost of architectural project - VAT included:</span>
        <span class="right">${total}</span>
        <span class="right">USD</span>
      </div>

      <div class="bottom-info ${this.state.botInput3 ? "" : "unfilled"}">
        Additional information or notes:
        <div class="bordered">
          ${this.state.botInput3}
        </div>
      </div>
    </div>
    <div class="date">
      ${new Date().toLocaleDateString(undefined, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })}
    </div>
  </main>
</body>
</html>
`;
    html2pdf()
      .set({
        html2canvas: { scale: 4 },
        pageBreak: { mode: "css" },
        margin: 0.2,
        filename: "document.pdf",
        jsPDF: { unit: "in", format: [11.7, 8.3], orientation: "portrait" },
      })
      .from(pdfContent)
      .save();
  };

  render() {
    const {
      selectedOption,
      selectedSubOption,
      numberInput1,
      numberInput2,
      calculatedCost,
      checkedCost,
      gdpDisabled,
      selectedOptionChanged,
      selectedOptionClick,
      isOpen,
      lang,
    } = this.state;

    const availableSubOptions =
      (this.state.lang === "En"
        ? this.optionsMapEn[selectedOption]
        : this.optionsMap[selectedOption]) || [];
    const gdp = Math.round((checkedCost * 18) / 100);
    if (!selectedOptionChanged) {
      this.setState({
        selectedOptionClick: "option1",
        selectedOptionChanged: true,
      });
    }

    document.addEventListener("DOMContentLoaded", function () {
      document
        .getElementById("generate-pdf-button")
        .addEventListener("click", this.generatePDF);
    });

    return (
      <div className="container">
        <div className="burger-menu">
          <div className="burger-inner-wrap">
            <a href="/">
              <img
                src="./logo.png"
                width="150px"
                height="40px"
                className="logo"
                alt=""
              />
            </a>
            <div className="burger-buttons-wrap">
              <button
                className="the-button"
                onClick={() => {
                  const newLang = this.state.lang === "En" ? "Geo" : "En";
                  this.setState({ lang: newLang });
                  localStorage.setItem("selectedLanguage", newLang);
                }}
              >
                {lang === "En" ? "Geo" : "En"}
              </button>
              <button className="burger-button" onClick={this.toggleMenu}>
                ☰
              </button>
            </div>
          </div>
          <nav className={isOpen ? "open" : ""}>
            <ul>
              <li>
                <Link to="/">
                  {this.state.lang === "En" ? "Main" : "მთავარი"}
                </Link>
              </li>
              <li>
                <Link to="/Arch">
                  {this.state.lang === "En" ? "Architecture" : "არქიტექტურა"}
                </Link>
              </li>
              <li>
                <Link to="/City">
                  {this.state.lang === "En"
                    ? "City Planning"
                    : "ქალაქგეგმარება"}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header">
          <a href="/">
            <img
              src="./logo.png"
              width="150px"
              height="40px"
              className="logo"
              alt=""
            />
          </a>
          <nav>
            <ul>
              <li>
                <Link to="/">
                  {this.state.lang === "En" ? "Main" : "მთავარი"}
                </Link>
              </li>
              <li>
                <Link to="/Arch">
                  {this.state.lang === "En" ? "Architecture" : "არქიტექტურა"}
                </Link>
              </li>
              <li>
                <Link to="/City">
                  {this.state.lang === "En"
                    ? "City Planning"
                    : "ქალაქგეგმარება"}
                </Link>
              </li>
              <li>
                <button
                  className="the-button"
                  onClick={() => {
                    const newLang = this.state.lang === "En" ? "Geo" : "En";
                    this.setState({ lang: newLang });
                    localStorage.setItem("selectedLanguage", newLang);
                  }}
                >
                  {lang === "En" ? "Geo" : "En"}
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div className="main-content">
          <div className="descr">
            <label>
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).agwera
              }
            </label>
          </div>

          <div className="clicks">
            <button
              className={`option-button ${
                selectedOptionClick === "option1" ? "selected" : ""
              } ${selectedOptionChanged ? "changed" : ""}`}
              onClick={() => this.handleOptionChangeButton("option1")}
            >
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).option1
              }
            </button>
            <button
              className={`option-button ${
                selectedOptionClick === "option2" ? "selected" : ""
              } ${selectedOptionChanged ? "changed" : ""}`}
              onClick={() => this.handleOptionChangeButton("option2")}
            >
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).option2
              }
            </button>
          </div>

          <div className="options">
            <label className="option-label" htmlFor="mainOptionSelect">
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).selectMainOption
              }
            </label>
            <select
              id="mainOptionSelect"
              value={selectedOption}
              onChange={this.handleOptionChange}
            >
              <option value="">
                {
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).chooseOption
                }
              </option>
              {Object.keys(
                this.state.lang === "En" ? this.optionsMapEn : this.optionsMap
              ).map((mainOption) => (
                <option key={mainOption} value={mainOption}>
                  {
                    (this.state.lang === "En"
                      ? this.optionLabelsEn
                      : this.optionLabels)[mainOption]
                  }
                </option>
              ))}
            </select>

            <div className="secondCo desktop">
              <div className="secondRo">
                <label className="option-label" htmlFor="subOptionSelect">
                  {
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).selectSubOption
                  }
                </label>

                <label className="category-label">
                  {
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).categoryLabel
                  }
                </label>
              </div>
              <div className="secondRo">
                <select
                  className="subOption"
                  id="subOptionSelect"
                  value={selectedSubOption}
                  onChange={this.handleSubOptionChange}
                  disabled={!selectedOption}
                >
                  <option value="">
                    {
                      (this.state.lang === "En"
                        ? this.commonPhrasesEn
                        : this.commonPhrases
                      ).subOpt
                    }
                  </option>
                  {availableSubOptions.map((subOpt) => (
                    <option key={subOpt} value={subOpt}>
                      {subOpt.split("[")[0].trim()}
                    </option>
                  ))}
                </select>

                <div className="category-box">
                  <div className="inline-div">
                    {
                      this.handleCategorySwitch(
                        this.state.selectedSubOption
                      )?.split("|")[0]
                    }
                  </div>
                  <div className="inline-div">
                    {
                      this.handleCategorySwitch(
                        this.state.selectedSubOption
                      )?.split("|")[1]
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="secondCo mobile">
              <div className="secondRo">
                <label className="option-label" htmlFor="subOptionSelect">
                  {
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).selectSubOption
                  }
                </label>
                <select
                  className="subOption"
                  id="subOptionSelect"
                  value={selectedSubOption}
                  onChange={this.handleSubOptionChange}
                  disabled={!selectedOption}
                >
                  <option value="">
                    {
                      (this.state.lang === "En"
                        ? this.commonPhrasesEn
                        : this.commonPhrases
                      ).subOpt
                    }
                  </option>
                  {availableSubOptions.map((subOpt) => (
                    <option key={subOpt} value={subOpt}>
                      {subOpt.split("[")[0].trim()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="secondRo">
                <label className="category-label">
                  {
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).categoryLabel
                  }
                </label>

                <div className="category-box">
                  <div className="inline-div">
                    {
                      this.handleCategorySwitch(
                        this.state.selectedSubOption
                      )?.split("|")[0]
                    }
                  </div>
                  <div className="inline-div">
                    {
                      this.handleCategorySwitch(
                        this.state.selectedSubOption
                      )?.split("|")[1]
                    }
                  </div>
                </div>
              </div>
              <div className="desc-text mobile">
                {
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).descText
                }
              </div>
            </div>
          </div>

          <div className="middle-wrap">
            <div className="checkbox-wrap">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="checkbox1"
                  onChange={this.toggleCheckbox1}
                  checked={this.state.checkbox1Checked}
                />
                <label htmlFor="checkbox1">
                  {
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).checkbox1
                  }
                </label>
              </div>
              <div className="multiplier-wrap">X 1.2</div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="checkbox2"
                  onChange={this.toggleCheckbox2}
                  checked={this.state.checkbox2Checked}
                />
                <label htmlFor="checkbox2">
                  {
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).checkbox2
                  }
                </label>
              </div>
              <div className="multiplier-wrap">X 1.4</div>
            </div>
            <div className="desc-text desktop">
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).descText
              }
            </div>
          </div>

          <div className="row">
            <div className="left-box">
              <input
                type="number"
                id="costInput2"
                value={numberInput2}
                onChange={this.handleNumberInput2}
                inputMode="number"
                onKeyDown={this.handleKeyDown2}
                onWheel={(e) => {
                  e.preventDefault();
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                }}
                onTouchMove={(e) => {
                  e.preventDefault();
                }}
                className="enter"
                placeholder={
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).numberEnter2
                }
              />
            </div>
            <div className="right-box">
              <span>
                {
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).ganmart2
                }
              </span>
            </div>
          </div>
          <div className="row">
            <div className="left-box">
              <input
                type="text"
                id="costInput1"
                value={numberInput1}
                onChange={this.handleNumberInput1}
                className="enter"
                placeholder={
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).numberEnter1
                }
              />
            </div>
            <div className="right-box">
              <span>
                {
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).ganmart1
                }
              </span>
            </div>
          </div>
          <div className="𓀌">
            <textarea
              name="message"
              rows="4"
              cols="30"
              className="botlane-input"
              type="text"
              onChange={(e) => this.handleInputChange(e, 3)}
              placeholder={
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).placeHolder3
              }
            />
          </div>
          <div className="botlane-wrap">
            <div className="botlane-left-wrap">
              <div className="botlane-left-input-wrap">
                <textarea
                  name="message"
                  rows="5"
                  cols="30"
                  className="botlane-input"
                  type="text"
                  onChange={(e) => this.handleInputChange(e, 1)}
                  placeholder={
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).placeHolder1
                  }
                />
                <textarea
                  name="message"
                  rows="5"
                  cols="30"
                  className="botlane-input"
                  type="text"
                  onChange={(e) => this.handleInputChange(e, 2)}
                  placeholder={
                    (this.state.lang === "En"
                      ? this.commonPhrasesEn
                      : this.commonPhrases
                    ).placeHolder2
                  }
                />
              </div>
              <div className="botlane-left-button">
                <button
                  className="pdf"
                  id="generate-pdf-button"
                  onClick={
                    this.state.lang === "En"
                      ? this.generatePDFeng
                      : this.generatePDF
                  }
                >
                  {this.state.lang === "En" ? "Download" : "ჩამოტვირთე"} PDF
                </button>
              </div>
            </div>
            <div className="botlane-right-wrap">
              <div className="table">
                {(this.state.lang === "En" ? tableDataEn : tableData).map(
                  (data, index) => (
                    <div className="table-line" key={index}>
                      <div className="checkbox-container botlane-chb">
                        <input
                          type="checkbox"
                          id={`table-checkbox${index + 1}`}
                          onChange={() => this.handleCheckboxChange(index + 1)}
                          checked={
                            this.state[`tableCheckbox${index + 1}Checked`]
                          }
                        />
                        <label htmlFor={`table-checkbox${index + 1}`}></label>
                      </div>
                      <label className="tb tb1">{data.text1}</label>
                      <label className="tb tb2">{data.text2}</label>
                      <span className="tb tb3">
                        {this.state[`tableCheckbox${index + 1}Checked`]
                          ? Math.round(calculatedCost / data.division)
                          : "---"}
                      </span>
                    </div>
                  )
                )}
              </div>
              <div className="botlane-warning">
                {
                  (this.state.lang === "En"
                    ? this.commonPhrasesEn
                    : this.commonPhrases
                  ).botlaneWarning
                }
              </div>
            </div>
          </div>

          <div className="calculator">
            <div className="input-row">
              <div className="results">
                <p className="onePrice">
                  <span className="bold-text">
                    {
                      (this.state.lang === "En"
                        ? this.commonPhrasesEn
                        : this.commonPhrases
                      ).onePrice
                    }
                  </span>{" "}
                  <div className="USD-boxes">
                    <span className="value-border">
                      {Math.round(checkedCost / numberInput2) || 0}
                    </span>
                    <span className="USD">
                      {
                        (this.state.lang === "En"
                          ? this.commonPhrasesEn
                          : this.commonPhrases
                        ).USD
                      }
                    </span>
                  </div>
                </p>

                <p>
                  <span className="bold-text">
                    {
                      (this.state.lang === "En"
                        ? this.commonPhrasesEn
                        : this.commonPhrases
                      ).cost
                    }
                  </span>{" "}
                  <div className="USD-boxes">
                    <span className="value-border">
                      {Math.round(checkedCost) || 0}
                    </span>
                    <span className="USD">
                      {
                        (this.state.lang === "En"
                          ? this.commonPhrasesEn
                          : this.commonPhrases
                        ).USD
                      }
                    </span>
                  </div>
                </p>

                <p>
                  <span className="checkbox-container gdp-checkbox">
                    <input
                      type="checkbox"
                      id="disableGDP"
                      onChange={this.toggleGDP}
                      checked={this.state[`gdpDisabled`]}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="disableGDP">
                      {
                        (this.state.lang === "En"
                          ? this.commonPhrasesEn
                          : this.commonPhrases
                        ).gdp
                      }
                    </label>
                  </span>
                  <div className="USD-boxes">
                    <span className="value-border">
                      {gdpDisabled ? gdp : "---"}{" "}
                    </span>
                    <span className="USD">
                      {
                        (this.state.lang === "En"
                          ? this.commonPhrasesEn
                          : this.commonPhrases
                        ).USD
                      }
                    </span>
                  </div>
                </p>
                <p>
                  <span className="bold-text">
                    {
                      (this.state.lang === "En"
                        ? this.commonPhrasesEn
                        : this.commonPhrases
                      ).totalCost
                    }
                  </span>{" "}
                  <div className="USD-boxes">
                    <span className="value-border">
                      {Math.round(checkedCost + (gdpDisabled ? gdp : 0))}{" "}
                    </span>
                    <span className="USD">
                      {
                        (this.state.lang === "En"
                          ? this.commonPhrasesEn
                          : this.commonPhrases
                        ).USD
                      }
                    </span>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="cost-text">
            <span>
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).span1
              }
            </span>
          </div>
          <div className="test-text">
            <p>
              {
                (this.state.lang === "En"
                  ? this.commonPhrasesEn
                  : this.commonPhrases
                ).testText
              }
            </p>
          </div>
        </div>

        <footer>
          <div></div>
          <div className="footer-line"></div>
          <div className="contact-info">
            <h2>
              {this.state.lang === "En" ? "Email" : "ელ.ფოსტა"}:
              info@forarchitects.ge
            </h2>
            <h2>
              {this.state.lang === "En"
                ? "Contact Number"
                : "საკონტაქტო ნომერი"}
              : (+995) 555 18 00 08
            </h2>
          </div>
        </footer>
      </div>
    );
  }
}

export default Arch;
