import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./Main.css";

function City() {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('selectedLanguage') || 'En');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = () =>{
    const newLang = lang === 'En' ? 'Geo' : 'En';
    setLang(newLang);
    localStorage.setItem('selectedLanguage', newLang);
  };


  return (
    <div className="container">
      <div className="burger-menu">
        <div className="burger-inner-wrap">
          <a href="/">
            <img src="./logo.png" width='150px' height='40px' className="logo" alt=""/>
          </a>
          <div className="burger-buttons-wrap">
            <button className="the-button" onClick={changeLanguage}>{lang === 'En' ? "Geo" : "En"}</button>
            <button className="burger-button" onClick={toggleMenu}>
              ☰
            </button>
          </div>
        </div>
        <nav className={isOpen ? 'open' : ''}>
          <ul>
            <li>
              <Link to="/">{lang === 'En' ? "Main" :"მთავარი"}</Link>
            </li>
            <li>
              <Link to="/Arch">{lang === 'En' ? "Architecture" :"არქიტექტურა"}</Link>
            </li>
            <li>
              <Link to="/City">{lang === 'En' ? "City Planning" :"ქალაქგეგმარება"}</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="header">
      <a href="/">
        <img src="./logo.png" width='150px' height='40px' className="logo" alt=""/>
      </a>
      <nav>
        <ul>
          <li>
            <Link to="/">{lang === 'En' ? "Main" :"მთავარი"}</Link>
          </li>
          <li>
            <Link to="/Arch">{lang === 'En' ? "Architecture" :"არქიტექტურა"}</Link>
          </li>
          <li>
            <Link to="/City">{lang === 'En' ? "City Planning" :"ქალაქგეგმარება"}</Link>
          </li>
          <li>
            <button className="the-button" onClick={changeLanguage}>{lang === 'En' ? "Geo" : "En"}</button>
          </li>
        </ul>
      </nav>
    </div>

      <div className="citycont">
        <h2 style={{ textAlign: "center", color: '#ae480b', fontStyle: "italic"}}>
          გვერდი დამუშავების პროცესშია და მალე დაემატება
        </h2>
      </div>
    </div>
  );
}

export default City;
