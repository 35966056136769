import React from "react";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import Page1 from "./Main";
import Page2 from "./Arch";
import Page3 from "./City";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Page1/>} />
        <Route path="/Arch" element={<Page2/>} />
        <Route path="/City" element={<Page3/>} />
      </Routes>
    </Router>
  );
}

export default App;
